import React, { forwardRef } from 'react';
import { Input as Cmpt } from '@/components/ui/input';

const Input = forwardRef(({ type, className, currencySymbol, showCurrencySymbol, isForDateTable, handleClearSearch, ...props }, ref) => {
	return (
		<Cmpt
			type={type}
			className={className}
			currencySymbol={currencySymbol}
			showCurrencySymbol={showCurrencySymbol}
			isForDateTable={isForDateTable}
			ref={ref}
			handleClearSearch={handleClearSearch}
			{...props}
		/>
	);
});

export default Input;
